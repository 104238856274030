import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PandellLogo from "../images/logo-pandell-partner.png"
import irth from "../images/irth.png"
import GrooperLogo from "../images/Grooper-logo-small.png"
// import NTBAssoc from "../images/ntb_assoc_logo.png"
// import MottMacDonald from "../images/mott_macdonald_logo.svg"

const Partnerships = () => (
  <Layout>
    <SEO title="Partnerships" />

    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Partnerships</h1>
        <div className="row">
          <div className="col-md-9 mb-5">
            <p className="h3 text-orange">
              Strategic alignment with Irth Solutions, top Survey & Engineering firms
              to provide the best overall service to our clients.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 px-md-5 mb-4">
            <div className="text-center mb-3">
              <a
                href="https://www.irthsolutions.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  src={irth}
                  alt="Irth Solutions"
                  width="400px"
                  className="img-fluid"
                  style={{ filter: "brightness(0) invert(1)" }}
                />
              </a>
            </div>
            <p className="text-left">
            Irth's market-leading SaaS platform improves resilience and reduces risk in the sustainable delivery of essential services that millions of people and businesses rely on every day.
            </p>
          </div>
          <div className="col-md-4 px-md-5 mb-4">
            <div className="text-center mb-3">
              <a
                href="https://www.bisok.com/intelligent-document-processing/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  src={GrooperLogo}
                  alt="Grooper"
                  width="400px"
                  className="img-fluid"
                />
              </a>
            </div>
            <p className="text-left">
              Grooper is an intelligent document processing and digital data integration platform 
              that empowers rapid innovation for organizations processing and integrating large 
              quantities of difficult data.
            </p>
          </div>
          {/*<div className="col-md-4 px-md-5 mb-4">
            <div className="text-center mb-3">
              <img
                loading="lazy"
                src={NTBAssoc}
                alt="NTB Associates, Inc."
                width="400px"
                className="img-fluid"
              />
            </div>
            <p>
              NTB Associates, Inc. was founded in 1986. The company's line of
              business includes providing professional engineering services.
            </p>
          </div>
          <div className="col-md-4 px-md-5 mb-4">
            <div className="text-center mb-3">
              <img
                loading="lazy"
                src={MottMacDonald}
                alt="Mott MacDonald"
                width="400px"
                className="img-fluid"
              />
            </div>
            <p>
              Mott MacDonald is a global engineering, management and development
              consultancy.
            </p>
          </div>*/}
        </div>
      </div>
    </div>
  </Layout>
)

export default Partnerships
